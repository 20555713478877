(function ($) {

  var $html = $('html'),
      $header = $('.site-header'),
      $wrapper = $('.site-wrapper');

  var setupHeader = function () {
    if (!$header.hasClass('stuck')) {
      var sticky = new Waypoint.Sticky({
        element: $('.site-header')[0],
        wrapper: '<div class="sticky-wrapper sticky-header" />',
        offset: function () {
          return -$header.position().top;
        }
      });
    }

    $('.btn-menu').on('click', function () {
      $html.toggleClass('menu-opened');
    });
  };

  var setupAnimatedElements = function () {
    var $animatedEl = $('.animate');

    $(window).on('scroll', $.throttle(250, function () {
      $animatedEl.each(function () {
        var bottom_of_object = $(this).offset().top + 150;
        var bottom_of_window = $(window).scrollTop() + $(window).height();

        /* If the object is completely visible in the window, fade it it */
        if ( bottom_of_window > bottom_of_object ) {
          var dataOpacity = $(this).data('opacity');
          var opacity = dataOpacity ? dataOpacity : '1';

          $(this).animate({
            'opacity': opacity,
            'top': 0
          }, 500);
        }
      });
    })).trigger('scroll');
  };

  var setupOverlays = function () {
    $('.overlay-close').on('click', function (e) {
      e.preventDefault();
      $html.removeClass('overlay-opened');
    });

    $('a[href="#benefits-overlay"]').on('click', function (e) {
      e.preventDefault();
      $html.addClass('overlay-opened');
    });

    $('a[href="#about-us-overlay"]').on('click', function (e) {
      e.preventDefault();
      $html.addClass('overlay-opened');
    });
  };

  var setupExperiences = function () {
    var windowWidth = -1;

    $('.experiences-blocks').on('click', 'a.block', function (e) {
      e.preventDefault();

      var index = $(this).data('open');
      var $block = $('.experience-block-content[data-index=' + index +']');

      if (!$block.hasClass('active')) {
        $('.experience-block-content.active').removeClass('active').slideToggle('fast');
      }

      $block.toggleClass('active').slideToggle('fast', function () {
        if ($(window).width() <= 620) {
          $('html, body').animate({
            scrollTop: ($block.offset().top - $header.outerHeight())
          }, 300);
        }
      });

      $(this).removeClass('inactive');

      if ($('.experience-block-content.active').length) {
        $('.experiences-blocks .block').not($(this)).addClass('inactive');
      }
      else {
        $('.experiences-blocks .block.inactive').removeClass('inactive');
      }
    });

    var reorderExperiences = function () {
      if ($(window).width() === windowWidth) {
        return;
      }
      else {
        windowWidth = $(window).width();
      }

      var total = $('.experience-block-content').length;
      var columns = 1;

      if ($(window).width() >= 1024) {
        columns = 3;
      }
      else if ($(window).width() >= 620) {
        columns = 2;
      }
      else {
        columns = 1;
      }

      $('.experiences-blocks .block.inactive').removeClass('inactive');
      $('.experience-block-content.active').removeClass('active').slideToggle('fast');

      for (var i = 0; i < total; i++) {
        var index = i;
        var newIndex = 0;
        var $el = $('.experience-block-content[data-index=' + index + ']');

        if (columns === 1) {
          $el.insertAfter($('.experiences-blocks a[data-open=' + index + ']'));
        }

        if (columns === 2) {
          newIndex = index % 2 === 0 ? index + 1 : index;
          $el.insertAfter($('.experiences-blocks a[data-open=' + newIndex + ']'));
        }

        if (columns === 3) {
          newIndex = index % 3 === 0 ? index + 2 : index;
          if (index % columns === 1) {
            newIndex = index + 1;
          }

          $el.insertAfter($('.experiences-blocks a[data-open=' + newIndex + ']'));
        }
      }
    };

    $(window).on('resize', $.throttle(250, reorderExperiences)).trigger('resize');
  };

  var setupForms = function () {
    var $form = $('.ninja-forms-form');

    if (!$form.length) {
      return;
    }

    $form.prop('autocomplete', 'off');

    $form.find('input[type=text]').on('focus', function () {
      $(this).closest('.field-wrap').addClass('input-filled');
    }).on('blur', function () {
      if ($(this).val() === '') {
        $(this).closest('.field-wrap').removeClass('input-filled');
      }
    });
  };

  var setupTestimonials = function () {
    var $slider = $('.testimonials-slider');

    if (!$slider.length) {
      return;
    }

    $slider.slick({
      autoplay: true,
      fade: true,
      arrows: false,
      speed: 1000,
      swipe: false,
      draggable: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      autoplaySpeed: 6000
    });
  };

  var setupBlog = function () {
    var $blogWrapper = $('.blog-container .blog-wrapper');

    if (!$blogWrapper.length) {
      return false;
    }

    $('.blog-load-more').on('click', function (e) {
      e.preventDefault();

      var $this = $(this);

      $.ajax({
        type: 'get',
        url: $this.data('load-url'),
        dataType: 'json',
        data: {
          offset: $this.data('load-offset')
        },
        beforeSend: function () {
          $this.prop('disabled', true);
        },
        success: function (resp) {
          var next = resp.next_offset;
          var $html = $(resp.html);
          var html = [];

          $html.each(function (index, el) {
            if (el.nodeName === 'DIV') {
              $(el).addClass('loading');
              html.push(el);
            }
          });

          salvattore.appendElements($blogWrapper[0], html);

          setTimeout(function () {
            $('.block.loading').removeClass('loading');
          }, 100);

          if (next === -1) {
            $this.fadeOut('fast');
          }
          else {
            $this.data('load-offset', next);
          }
        },
        complete: function () {
          $this.prop('disabled', false);
        }
      });
    });
  };

  var setupBlogPost = function () {
    if (!$('body').hasClass('single-post')) {
      return;
    }

    $('.gallery').each(function (index, el) {
      $(el).slick({
        autoplay: true,
        fade: true,
        arrows: false,
        speed: 1000,
        swipe: false,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        autoplaySpeed: 3000
      });
    });
  };

  var setupSlideshows = function () {
    var $slideshows = $('.block-slideshow');

    if (!$slideshows.length) {
      return;
    }

    $slideshows.each(function (index, el) {
      $(el).find('.block-slides').on('init beforeChange afterChange', function () {
        $(window).trigger('resize');
      }).slick({
        autoplay: true,
        fade: true,
        arrows: false,
        speed: 1000,
        swipe: false,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        autoplaySpeed: 3000
      });
    });
  };

  var init = function () {
    setupHeader();
    setupOverlays();
    setupExperiences();
    setupForms();
    setupTestimonials();
    setupBlog();
    setupBlogPost();
    setupSlideshows();
  };

  init();

})(window.jQuery);
